<template>
  <div>
    <v-toolbar class="rounded-lg transparent" flat>
      <v-tabs>
        <v-tab class="b-700" to="/users" exact-path>
          <v-icon left>mdi-account-multiple-outline</v-icon>
          Utilisateurs
        </v-tab>
        <v-tab class="b-700" to="/users/roles">
          <v-icon left>mdi-folder-cog-outline</v-icon>
          Rôles
        </v-tab>
      </v-tabs>
    </v-toolbar>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>