<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row justify="center" align="center">
        <v-col cols="12">
          <Toolbar/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <div>
                <v-text-field v-model="search" dense filled rounded
                              append-icon="mdi-magnify"
                              placeholder="Recherche..."
                              class="w-300"
                              single-line hide-details></v-text-field>
              </div>
              <v-spacer/>
              <v-btn text class="text-none" disabled>
                {{ roles.length }} Élément(s)
              </v-btn>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="getRoles">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
              <v-btn color="blue" dark class="ml-1" fab small depressed
                     @click="addItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-data-table v-else :headers="headers" :items="roles" :search="search">
              <template v-slot:item.permissions="{item}">
                <v-chip small color="blue" dark @click="[
                     permissionsDialog = true,
                     D_permissions = item.array_permissions
                   ]">
                  <v-icon left small>mdi-cogs</v-icon>
                  {{ item.array_permissions.length }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="editItem(item)"
                             :disabled="item.name ==='Super administrateur'">
                        <v-icon size="20">mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifier</span>
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="deleteItem(item.id)"
                             :disabled="item.name ==='Super administrateur'">
                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs-->
      <v-dialog v-model="dialog" width="600" persistent scrollable>
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <RoleForm :permissions="permissions" @close="dialog=false" @get-roles="getRoles" ref="roleForm"/>
            <v-skeleton-loader v-if="permissions.length === 0" type="article"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog width="600" v-model="permissionsDialog" scrollable>
        <v-card>
          <v-card-title>
            Autorisations
            <v-spacer/>
            <v-icon @click="permissionsDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12" v-for="(permission ,key) in permissions" :key="key">
                <v-card outlined>
                  <v-card-title class="fs-15 pa-2">
                    {{ key }}
                    <v-spacer/>
                    <v-progress-circular
                        :rotate="360"
                        :size="45"
                        :width="5"
                        :value="totalSelected(permission) * 100 / permission.length"
                        color="blue">
                      {{ totalSelected(permission) }}/{{ permission.length }}
                    </v-progress-circular>
                  </v-card-title>
                  <v-divider/>
                  <div class="pa-2">
                    <v-chip filter v-for="(p ,i) in permission"
                            class="mb-2 mt-2 mr-2"
                            :key="i"
                            :value="p.name"
                            :class="D_permissions.includes(p.name) ? 'blue--text v-chip--active' : ''">
                      <v-icon left v-if="D_permissions.includes(p.name)">mdi-check</v-icon>
                      {{ p.name }}
                    </v-chip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import RoleForm from "./RoleForm";
import Toolbar from "../Toolbar";

export default {
  components: {RoleForm, Toolbar},
  data() {
    return {
      search: '',
      headers: [
        {text: 'Nom', value: 'name'},
        {text: 'Description', value: 'description'},
        {text: 'Autorisations', value: 'permissions'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      overlay: false,
      dialog: false,
      permissionsDialog: false,
      dialogTitle: '',
      id: '',
      roles: [],
      permissions: [],
      loading: false,
      D_permissions: []
    }
  },
  methods: {
    addItem() {
      this.dialogTitle = 'Ajouter un rôle'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.roleForm.add()
      }, 100)
    },
    editItem(item) {
      this.dialogTitle = 'Modifier un rôle'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.roleForm.edit(item)
      }, 100)
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getRoles() {
      this.roles = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/roles').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.roles = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    getPermissions() {
      this.permissions = []
      this.$Progress.start()
      HTTP.get('/roles/permissions').then((res) => {
        this.$Progress.finish()
        this.permissions = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    totalSelected(permission) {
      let total = 0
      let _vm = this
      permission.map(function (el) {
        _vm.D_permissions.includes(el.name) ? total = total + 1 : false
      })
      return total
    }
  },
  created() {
    this.getRoles()
    this.getPermissions()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/roles/delete/' + this.id).then(() => {
          let i = this.roles.findIndex(item => item.id === this.id)
          this.$delete(this.roles, i)
          this.$successMessage = 'Ce rôle a été supprimé avec succès'
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>