<template>
  <div>
    <form @submit.prevent="save">
      <v-text-field label="Nom *"
                    @input="errors && errors.name ? errors.name = '' : false"
                    :error-messages="errors.name" v-model="form.name"/>
      <v-textarea label="Description"
                  @input="errors && errors.description ? errors.description = '' : false"
                  :error-messages="errors.description" v-model="form.description" rows="2"/>
      <label class="fs-16">
        Choisir les autorisations * :
      </label>

      <v-row class="mt-2">
        <v-col cols="12" v-for="(permission ,key) in permissions" :key="key">
          <v-card outlined>
            <v-card-title class="fs-15 pa-2">
              {{ key }}
              <v-spacer/>
              <v-progress-circular
                  :rotate="360"
                  :size="45"
                  :width="5"
                  :value="totalSelected(permission) * 100 / permission.length"
                  color="blue">
                {{ totalSelected(permission) }}/{{ permission.length }}
              </v-progress-circular>
            </v-card-title>
            <v-divider/>
            <div class="pa-2">
              <v-chip-group
                  column multiple v-model="form.permissions">
                <v-chip filter v-for="(p ,i) in permission"
                        :key="i" :value="p.name" active-class="blue--text">
                  {{ p.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div class="v-text-field__details" v-if="errors && errors.permissions && form.permissions.length === 0">
        <div class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">{{ errors.permissions[0] }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between mt-8">
        <v-spacer/>
        <v-btn color="primary" :loading="btnLoading" depressed class="rounded-lg"
               type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['permissions'],
  data() {
    return {
      btnLoading: false,
      form: {
        id: '',
        name: '',
        description: '',
        permissions: [],
      },
      errors: {},
    }
  },
  methods: {
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.form.permissions = item.array_permissions
      this.form.description = item.description || ''
      this.errors = {}
    },
    add() {
      this.form.id = ''
      this.form.name = ''
      this.form.description = ''
      this.form.permissions = []
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      this.errors = {}
      this.$Progress.start()
      const url = this.form.id ? '/roles/update' : '/roles/store'
      HTTP.post(url, this.form).then(() => {
        this.$successMessage = 'Ce rôle a été enregistré avec succès'
        this.btnLoading = false
        this.$Progress.finish()
        this.$emit('get-roles')
        if (this.form.id) {
          this.$emit('close')
        } else {
          this.form = {
            id: '',
            name: '',
            description: '',
            permissions: [],
          }
        }
      }).catch(err => {
        this.btnLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
    totalSelected(permission) {
      let total = 0
      let _vm = this
      permission.map(function (el) {
        _vm.form.permissions.includes(el.name) ? total = total + 1 : false
      })
      return total
    }
  },
}
</script>

<style scoped>

</style>